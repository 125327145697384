import request from '@/api/request.js'

// 代理首页
export function Agentindex (data) {
  return request.post('/agent/index')
}
//   代理设备检测数图表
export function machineTestChart (data) {
  return request.post('/agent/machineTestChart', data)
}
// 代理设备列表
export function machineList (data) {
  return request.post('/agent/machineList', data)
}
// 代理设备类型列表
export function machineTypeList (data) {
  return request.post('/agent/machineTypeList', data)
}
// 代理绑定设备
export function machineBind (data) {
  return request.post('/agent/machineBind', data)
}
// 代理解除设备
export function machineRelieve (data) {
  return request.post('/agent/machineRelieve', data)
}
// 代理学校列表
export function schoolList (data) {
  return request.post('/agent/schoolList', data)
}
// 代理设备详情状态 绑定时间 设备名称等
export function machineDetailsAll (data) {
  return request.post('/agent/machineDetailsAll', data)
}
// 代理设备详情折线图视力检测
export function machineDetails (data) {
  return request.post('/agent/machineDetails', data)
}
// 代理设备详情分页视力检测
export function machineDetailsPage (data) {
  return request.post('/agent/machineDetailsPage', data)
}
// 代理设备详情校园卫士
export function machineDetailscamera (data) {
  return request.post('/agent/machineDetailscamera', data)
}
// 代理学校绑定设备
export function schoolBindMachine (data) {
  return request.post('/agent/schoolBindMachine', data)
}
// 代理学校设备回收
export function schoolRecoveryMachine (data) {
  return request.post('/agent/schoolRecoveryMachine', data)
}
// 省市校
export function proviceCitySchool (data) {
  return request.post('/agent/proviceCitySchool')
}
// 代理学校绑定申请
export function schoolBindApply (data) {
  return request.post('/agent/schoolBindApply', data)
}
// 广告平台页面省市区学校类型
export function provinceCityArea () {
  return request.post('/adv/provinceCityArea')
}
// 广告平台页面学校信息
export function getSchool (data) {
  return request.post('/adv/getSchool', data)
}
// 广告平台页面年级信息
export function getClassType (data) {
  return request.post('/adv/getClassType', data)
}
// 广告平台页面添加广告
export function advAdd (data) {
  return request.post('/adv/advAdd', data)
}
// 广告平台页面广告列表
export function advList (data) {
  return request.post('/adv/advList', data)
}
// 广告资质管理
export function qualificationsList (data) {
  return request.post('/adv/qualificationsList', data)
}
// 删除广告资质
export function qualificationsDel (data) {
  return request.post('/adv/qualificationsDel', data)
}
// 上传广告资质
export function addAdvQualifications (data) {
  return request.post('/adv/addAdvQualifications', data)
}
// 编辑广告资质
export function editAdvQualifications (data) {
  return request.post('/adv/editAdvQualifications', data)
}
// 广告资质类型
export function qualificationsType (data) {
  return request.post('/adv/qualificationsType', data)
}
// 修改密码
export function setPass (data) {
  return request.post('/teacher/setPass', data)
}
// 修改密码发送验证码
export function setPassSms (data) {
  return request.post('/teacher/setPassSms', data)
}
// 广告平台页面广告信息
export function advInfo (data) {
  return request.post('/adv/advInfo', data)
}
// 广告平台页面广告用户反馈列表
export function feedBackList (data) {
  return request.post('/adv/feedBackList', data)
}
// 广告平台页面广告用户设置每日总预算
export function everyDayBudget (data) {
  return request.post('/adv/everyDayBudget', data)
}
// 广告平台页面广告用户设置广告的每日预算和单次出价
export function advPriceEdit (data) {
  return request.post('/adv/advPriceEdit', data)
}
// 广告平台页面获取广告主信息
export function advUserInfo (data) {
  return request.post('/adv/advUserInfo', data)
}
// 广告账户记录
export function accountRecent (data) {
  return request.post('/adv/accountRecent', data)
}
// 广告平台页面广告暂停/开启
export function advSuspend (data) {
  return request.post('/adv/advSuspend', data)
}
// 广告平台页面广告我的账户近期消费/广告展示量
export function advShowAmount (data) {
  return request.post('/adv/advShowAmount', data)
}
// 代理设置银行卡
export function SetBankInfo (data) {
  return request.post('/agent/SetBankInfo', data)
}
// 余额和收益图表和银行卡
export function advProfitShow (data) {
  return request.post('/agent/advProfitShow', data)
}
// 代理广告收益提现记录
export function agentadvprofitextract (data) {
  return request.post('/agent/agentadvprofitextract', data)
}
// 代理广告收益明细记录
export function agentadvprofitinfo (data) {
  return request.post('/agent/agentadvprofitinfo', data)
}
// 代理广告收益提现
export function advWithdrawal (data) {
  return request.post('/agent/advWithdrawal', data)
}
// 代理广告收益转余额
export function advTransferBalance (data) {
  return request.post('/agent/advTransferBalance', data)
}
// 代理首页 - 认证人数
export function authenticatedList (data) {
  return request.post('/agent/authenticatedList', data)
}
// 代理投放设备 - 历史投放位置
export function historySetList (data) {
  return request.post('/agent/historySetList', data)
}
// 代理投放设备 - 投放安防设备
export function saveCamera (data) {
  return request.post('/agent/saveCamera', data)
}
// 代理安防记录 - 认证列表
export function sclAuthenticatedList (data) {
  return request.post('/agent/sclAuthenticatedList', data)
}
// 代理安防记录 - 安防监测数据
export function machineArriveCountList (data) {
  return request.post('/agent/machineArriveCountList', data)
}
// 代理安防记录 - 总数统计
export function machineCountList (data) {
  return request.post('/agent/machineCountList', data)
}
// 代理学校应用节假日模板列表
export function sclTplList (data) {
  return request.post('/agent/sclTplList', data)
}
// 代理学校修改应用的节假日模板
export function setSclTpl (data) {
  return request.post('/agent/setSclTpl', data)
}
// 代理节假日模板列表
export function tplList (data) {
  return request.post('/agent/tplList', data)
}
// 代理删除模板
export function delTpl (data) {
  return request.post('/agent/delTpl', data)
}
// 代理添加修改模板
export function saveTemplate (data) {
  return request.post('/agent/saveTemplate', data)
}
// 学生数据导入
export function excelStuadd (data) {
  return request.post('/agent/excelStuadd', data)
}
// 学生数据导入学校查询
export function excelSearch () {
  return request.post('/agent/excelSearch')
}
// 学生测试数据导出
export function excelDaochu (data) {
  return request.post('/agent/excelDaochu',data)
}
// 学校年级 选择学校联动时
export function gradeSearch (data) {
  return request.post('/agent/gradeInfo', data)
}
// 学校班级 选择年级联动时
export function classSearch (data) {
  return request.post('/agent/classInfo', data)
}
// 代理填加学生
export function studentAdd (data) {
  return request.post('/agent/studentAdd', data)
}
// 学生数据导出
export function excelDaochuStu (data) {
  return request.post('/agent/excelDaochuStu',data)
}

// *********************新版*********************

// 操作日志
export function loginLog (data) {
  return request.post('/agent/ipLog',data)
}
// 学校日志
export function schoolLog (data) {
  return request.post('/agent/schoolLog',data)
}
// 健康日志
export function healthLog(data) {
  return request.post('/agent/healthLog', data)
}
// 设备日志
export function machineLog (data) {
  return request.post('/agent/machineLog',data)
}
// 查询代理下面的省市区
export function machineSsq (data) {
  return request.post('/agent/machineSsq',data)
}
// 查询学生健康列表
export function stuEyeSearchList (data) {
  return request.post('/school/stuEyeSearchList',data)
}
// 代理查询首页系统联系人
export function agentAdmin (data) {
  return request.post('/agent/agentAdmin')
}
// 查询视力检测折线图接口
export function machineDetailseyedaypic (data) {
  return request.post('/agent/machineDetailseyedaypic',data)
}
// 查询音视频折线图接口
export function machineDetailsvideodaypic (data) {
  return request.post('/agent/machineDetailsvideodaypic',data)
}
// 查询校园卫士折线图接口
export function machineDetailscameradaypic (data) {
  return request.post('/agent/machineDetailscameradaypic',data)
}
// 查询验光仪折线图接口
export function machineDetailsygydaypic (data) {
  return request.post('/agent/machineDetailsygydaypic',data)
}
// 查询收益折线图接口
export function agentProfitpic (data) {
  return request.post('/agent/agentProfitpic',data)
}

//代理查询 学生眼睛详情
export function healthStuEyeSearch(data) {
  return request.post('/school/stuEyeSearch', data)
}

//代理查询 学生眼睛单数据详情
export function stuEyeSingleSearch(data) {
  return request.post('/school/stuEyeSingleSearch', data)
}

//管理后台学生眼睛信息修改
export function stuEyeEdit(data) {
  return request.post('/school/stuEyeEdit', data)
}

//代理查询 学生眼睛单数据详情 折线图
export function stuEyeSinglePic(data) {
  return request.post('/school/stuEyeSinglePic', data)
}

//管理后台删除学生眼睛信息
export function stuEyeDel(data) {
  return request.post('/school/stuEyeDel', data)
}

//查询代理每日收益
export function agentProfitday(data) {
  return request.post("/agent/agentProfitday", data);
}

//查询代理下 学校每日收益
export function agentProfitSchool(data) {
  return request.post("/agent/agentProfitSchool", data);
}

//查询代理可提现钱数
export function agentProfitmoney(data) {
  return request.post("/agent/agentProfitmoney", data);
}

//查询代理总收益
export function agentProfitsum(data) {
  return request.post("/agent/agentProfitsum", data);
}

//代理提现申请记录列表
export function extractApplyList(data) {
  return request.post("/agent/extractApplyList", data);
}

//管理后台提现申请
export function extractApply(data) {
  return request.post("/agent/extractApply", data);
}
//代理提现进度列表
export function extractApplyRecord(data) {
  return request.post("/agent/extractApplyRecord", data);
}

//管理后台改密码
export function passwdChange(data) {
  return request.post("/agent/passwdChange", data);
}

//管理后台改电话
export function telChange(data) {
  return request.post("/agent/telChange", data);
}
//管理后台改电话
export function bankManageinfo(data) {
  return request.post("/agent/bankManageinfo", data);
}
//管理后台改电话
export function bankManage(data) {
  return request.post("/agent/bankManage", data);
}
//代理查询银行列表
export function bankList(data) {
  return request.post("/agent/bankList", data);
}

//代理查询银行列表
export function cameraList(data) {
  return request.post("/agent/captureList", data);
}


//管理后台个人认证
export function personAuth(data) {
  return request.post("/agent/personAuth", data);
}
//查询代理个人认证信息
export function personAuthinfo(data) {
  return request.post("/agent/personAuthinfo", data);
}

//管理后台企业认证
export function companyAuth(data) {
  return request.post("/agent/companyAuth", data);
}
//查询代理企业认证信息
export function companyAuthinfo(data) {
  return request.post("/agent/companyAuthinfo", data);
}

//管理后台查询代理个人信息
export function personInfo(data) {
  return request.post("/agent/personInfo", data);
}
//管理改个人信息
export function personCinfo(data) {
  return request.post("/agent/personCinfo", data);
}
//查询物流 平台
export function logisticsPt(data) {
  return request.post("/agent/logisticsPt", data);
}
//管理后台填写物流信息
export function logisticsNum(data) {
  return request.post("/agent/logisticsNum", data);
}
//修改验光仪密码
export function agentOptomPwd(data) {
  return request.post("/agent/agentOptomPwd", data);
}
// 设备开启停用
export function machineStart (data) {
  return request.post('/agent/machineStart', data)
}
// 查询邮寄地址
export function agentCompany () {
  return request.post('/agent/agentCompany')
}
// 代理 查询红光时长
export function agentHgList () {
  return request.post('/agent/agentHgList')
}


//==================门店==================
// 查询门店列表
export function shopList (data) {
  return request.post('/store/storeList',data)
}
// 查询门店设备列表
export function storeMachineList (data) {
  return request.post('/store/storeMachineList',data)
}
// 门店 投放设备
export function storeBindMachine (data) {
  return request.post('/store/storeBindMachine',data)
}
// 门店 回收设备
export function storeRecoveryMachine (data) {
  return request.post('/store/storeRecoveryMachine',data)
}
// 门店 添加门店
export function addStore (data) {
  return request.post('/store/addStore',data)
}
// 门店 查询门店信息
export function storeDetail (data) {
  return request.post('/store/storeDetail',data)
}
// 门店 修改门店
export function editStore (data) {
  return request.post('/store/editStore',data)
}
// 门店 证件类型/所在行业
export function storeMechanism (data) {
  return request.post('/store/storeMechanism',data)
}
// 查询自助点列表
export function zzdList (data) {
  return request.post('/store/zzdList',data)
}
// 自助点 投放设备
export function zzdBindMachine (data) {
  return request.post('/store/zzdBindMachine',data)
}
// 自助点 添加自助点
export function addZzd (data) {
  return request.post('/store/addZzd',data)
}
// 自助点 添加自助点
export function addCamera (data) {
  return request.post('/agent/captureAdd',data)
}
// 自助点 添加自助点
export function removeCamera (data) {
  return request.post('/agent/captureDel',data)
}
// 自助点 添加自助点
export function bindIp (data) {
  return request.post('/agent/bindIp',data)
}